<template>
  <div>
    <div class="container watermark-container">
      <van-nav-bar title="OA审批" />
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="(item, key) in list"
          :key="key"
          :title="item.name"
          :name="item.key"
        >
          <van-row>
            <van-col
              v-for="template in item.processTemplateList"
              :key="template.id"
              span="6"
            >
              <div class="item" @click="apply(template.id)">
                <img :src="template.iconUrl" />
                <span>{{ template.name }}</span>
              </div>
            </van-col>
          </van-row>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import api from '@/api/process'
import addWatermark from '@/utils/watermark'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'process',

  data() {
    return {
      list: [],
      activeNames: [0, 1, 2, 3, 4],
    }
  },

  created() {
    this.fetchData()
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo, // 从 Vuex 中获取 userInfo
    }),
  },
  mounted() {
    // 如果 userInfo 没有值，则调用 Vuex action 获取用户信息
    if (!this.userInfo) {
      this.loadUserInfoAndAddWatermark()
    } else {
      // 如果已有 userInfo，直接添加水印
      this.addWatermark()
    }
  },
  methods: {
    // 获取用户信息缓存
    ...mapActions({
      loadUserInfo: 'fetchUserInfo', // 映射 fetchUserInfo 到组件的方法
    }),
    // 异步方法：先加载用户信息，再添加水印
    async loadUserInfoAndAddWatermark() {
      try {
        await this.loadUserInfo() // 等待 Vuex action 完成
        this.addWatermark() // 用户信息加载完成后添加水印
      } catch (error) {
        // console.error('加载用户信息失败:', error)
      }
    },
    addWatermark() {
      // console.log('打印水印数据:' + this.userInfo.name)
      // 调用水印功能
      addWatermark({
        containerSelector: '.watermark-container', // 添加水印的目标容器
        text: '通用锁具 ' + this.userInfo.name, // 自定义水印文本
        font: '16px Arial',
        color: 'rgba(0, 0, 0, 0.15)',
        width: 200,
        height: 150,
        angle: -30,
      })
    },
    fetchData() {
      api.findProcessType().then((response) => {
        console.log(response.data)
        this.list = response.data
        //全部展开
        this.activeNames = []
        this.list.forEach((item, index) => {
          this.activeNames.push(index)
        })
      })
    },

    apply(id) {
      this.$router.push({ path: '/apply/' + id })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 50px;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
    span {
      font-size: 12px;
      padding: 8px 4px;
      text-align: center;
    }
  }
}
</style>
